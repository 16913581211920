<wp-sidebar-page>
  <wp-sidebar
    brand="Werfy"
    brandIcon="assets/images/werfy-icon.svg"
    brandLogo="assets/images/werfy-logo-pink.svg"
    slot="sidebar"
  >
    <wp-navigation slot="navigation">
      <wp-navigation-item
        *ngFor="let navigationItem of navigationItems"
        [children]="navigationItem.children"
        [icon]="navigationItem.icon"
        [isExact]="!!navigationItem.isExact"
        [name]="navigationItem.name"
        [route]="navigationItem.route"
      ></wp-navigation-item>
    </wp-navigation>
  </wp-sidebar>

  <wp-meta-navigation *ngIf="user$ | async as user">
    <wp-meta-navigation-item>
      <wp-notification-dot [isActive]="true">
        <wp-icon icon="bell" set="light"></wp-icon>
      </wp-notification-dot>
    </wp-meta-navigation-item>

    <wp-meta-navigation-item>
      <wp-icon icon="gear" set="light" tabindex="0"></wp-icon>
    </wp-meta-navigation-item>

    <wp-meta-navigation-item [isImage]="!!user.avatarUrl">
      <wp-avatar [url]="user.avatarUrl"></wp-avatar>

      <wp-user-summary
        [avatarUrl]="user.avatarUrl"
        [email]="user.email"
        [name]="formatName(user)"
        slot="expand"
      ></wp-user-summary>

      <wp-menu slot="expand">
        <wp-menu-item>
          <wp-icon icon="user-circle" set="duotone" slot="icon"></wp-icon>
          Manage my account
        </wp-menu-item>
        <wp-menu-item>
          <wp-icon icon="credit-card" set="duotone" slot="icon"></wp-icon>
          Billing & Payments
        </wp-menu-item>
        <wp-menu-item (itemClick)="onTickets()">
          <wp-icon icon="comments-question" set="duotone" slot="icon"></wp-icon>
          Support & Feedback
        </wp-menu-item>
        <wp-menu-item (itemClick)="onLogout()">
          <wp-icon icon="right-from-bracket" set="duotone" slot="icon"></wp-icon>
          Sign out
        </wp-menu-item>
      </wp-menu>
    </wp-meta-navigation-item>
  </wp-meta-navigation>

  <router-outlet></router-outlet>
</wp-sidebar-page>

<wp-bubble (bubbleClick)="showCreateTicketModal = !showCreateTicketModal"></wp-bubble>
<wp-create-ticket-modal
  (closeModal)="showCreateTicketModal = false"
  *ngIf="showCreateTicketModal"
></wp-create-ticket-modal>
